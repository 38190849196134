import { configPrepare } from "@/helpers/vue-good-table"

const i18nPrefix = "ota_management.company_list.table.columns"

const predicate = (x, y) => (x < y ? -1 : x > y ? 1 : 0)

const defaults = {
  thClass: "table-th",
  tdClass: "table-td",
  sortFn: (x, y, { field }) => {
    switch (field) {
      case "fee":
        return predicate(x.value, y.value)
      case "updated_at":
        return predicate(x.timestamp, y.timestamp)
      default:
        return predicate(x, y)
    }
  }
}

const fields = [
  {
    label: `${i18nPrefix}.name`,
    field: "name",
    thClass: "table-th name"
  },
  {
    label: `${i18nPrefix}.auto_publish`,
    field: "auto_publish",
    sortable: false,
    thClass: "table-th auto-publish"
  },
  {
    label: `${i18nPrefix}.fee`,
    field: "fee",
    thClass: "table-th markup-fee"
  },
  {
    label: `${i18nPrefix}.updated_at`,
    field: "updated_at",
    thClass: "table-th updated-at"
  }
]

export const columns = configPrepare(fields, { i18nPrefix, defaults, backendSort: false })
