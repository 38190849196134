import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import extractActions from "./actions"
import extractMutations from "./mutations"

const baseURI = "/ota_management/markup_settings"
const store = new StoreItemsModule({ baseURI: baseURI })

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
